import React, {useState,useEffect} from 'react'
import {formatDate} from '@startlibs/utils'
import {useRefState} from '@startlibs/core'

export const Timer = ({language = "en",dateFormat = "dd/mm/yyyy", time, alwaysDate, noHours, shortMonth, noPrefix, onInvalid}) => {
  const intervalRef = useRefState()
  const [secondsElapsed,setSecondsElapsed] = useState()
  const [lang,setLang] = useState()

  const tick = () => {
    setSecondsElapsed(i=>i+1)
  }

  const loadLanguage = (languageKey) => {
    import(`report-components/src/components/date.i18n/${languageKey}`).then((lang) => setLang(lang[languageKey]))
  }


  useEffect(() => {
    loadLanguage(language)
  },[language])

  useEffect(() => {
    intervalRef.set(setInterval(tick, 60 * 1000))
    return () => {
      clearInterval(intervalRef.get())
    }
  },[])

  if (!lang) return null

  if (!alwaysDate && time + 60 * 60 * 1000 > Date.now()) {
    return <span>{Math.round((Date.now() - time) / (60 * 1000)) + ' minutes ago'}</span>
  }
  const date = new Date(time)
  if (isNaN(date.getTime())) {
    return <span>{onInvalid ? onInvalid : 'Invalid date'}</span>
  }
  return <span>{!noPrefix && 'on '}{formatDate(date, dateFormat.replace('LL', shortMonth ? 'L' : 'LL') + (noHours ? '' : ' - hh:mm'),(type,index) => lang[type+'s'][index])}</span>
}